<div class="main_left_container">
    <div class="both">
        <div class="tab">
            <button class="tablink1"(click)="gotoAlready()">Already Approved</button>
        </div>
        <div class="tab2">
            <button class="tablink2"(click)=" gotoYettobe()">Yet To Be Approved</button>
        </div>
    </div>
    <div class="middle_container">
        <div class="recent_title">
            <span class="txt">Already Approved</span>
        </div>
      <button *ngIf="isFilterSet" type="button" class="button-clear" (click)="clear()" >
        <div class="both_filter">
            <i class="material-icons md-clear-icon" >close</i> Clear</div></button>

        <button  [ngClass]="{'button-filter':toggle,'button-filter-enabled':!toggle}"(click)="filter()" >
            <div class="both_filter">
            <i class="material-icons md-filter_list"[ngClass]="{'md-filter_list':toggle,'md-filter_list-enabled':!toggle}" >filter_list</i> Filter</div></button>

    </div>
    <div class="lower_container">
        <table >
            <tr>
                <th>
                    <div class="table_header">
                        <span class="header_name">Company Name</span>

                    </div>
                </th>
                <th>
                    <div class="table_header">
                        <span class="header_name">User Name</span>

                    </div>
                </th>
                <th>
                    <div class="table_header">

                        <span class="header_name">Upload Time</span>

                    </div>
                </th>
                <th>Document Name</th>
                <th>Password</th>
                <th>Action</th>
            </tr>
            <tr *ngFor="let upload of uploads">
                <td>{{upload.company?.name}}</td>
                <td>{{upload.userName}}</td>
                <td>{{upload.uploadTime|date }}<br><span class="time">{{upload.uploadTime|date :'shortTime' }}</span></td>
                <td title="{{upload.documentName}}"class="doc-name-col">{{upload.documentName}}</td>
                <td>{{upload.inputDocumentPassword}}</td>
                <td>
                    <div class="Action">
                      <i class="material-icons md-get_app" (click)="downloadOutput(upload)">get_app</i>
                      <i class="material-icons md-picture_as_pdf"  (click)="downloadPdf(upload)">picture_as_pdf</i>
                      <i class="material-icons md-delete"
                         (click)="getdata(upload)" (click)="fileToDelete(upload.documentName)"  onclick="document.getElementById('deleteclass').style.display='block'" >delete</i>
                    </div>
                </td>
            </tr>
        </table>
    </div>
    <app-pagination [totalCount]="totalCount" (click)="reload()"></app-pagination>

</div>

<div id="deleteclass" class="w3-modal">
  <div class="w3-container w3-teal">
    <div class="Inner-tab">
      <div class="tittle-box">
        <label class="warning">WARNING</label>
      </div>
      <div class="are-you-sure-message">

        <span>Are you sure you want to delete "{{documenttoDelete}}" file ?</span>
      </div>
      <div class="btn-tab">
        <button class="cancle" onclick="document.getElementById('deleteclass').style.display='none'">CANCEL</button>
        <button class="yes" (click)="delete()" onclick="document.getElementById('deleteclass').style.display='none'" >YES</button>
      </div>
    </div>
  </div>
</div>



<form [formGroup]="dateFilterForm" id="filter-pop-up-tab" class="filter_tab">
    <div class="filter-tooltiptext" >
        <div class="filter-uploading-details">
            <div class="inner-tab">
                <span class="upload-timings">Upload Timings</span><br>
                <span class="select-start-date-an-text">Select start date and end date </span>
                 <br> <span *ngIf="submitted " class="errorMessage" >{{errorMsg}}</span>

            </div>
            <input class="start-date" formControlName="startDate"  type="text" id="start-date" name="startDate" onfocus="(this.type='date')" placeholder="Start Date">

            <input class="start-date" formControlName="endDate"  type="text" id="end-date" name="endDate" onfocus="(this.type='date' )" placeholder="End Date">

        </div>
        <div class="line"></div>

        <div class="filter-btn-tab ">
            <button (click)="clearFilter()" class="clear-filter " type="reset " value="Reset ">Clear Filter</button>

            <button class="cancel " (click)="cancelFilter()"  type="button" onclick="document.getElementById( 'filter-pop-up-tab').style.display='none' ">Cancel</button>

            <button class="apply" type="submit" (click)="onFilterSubmit()">Apply</button>
        </div>
    </div>
  </form>
