import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseService } from 'src/app/base.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PaginationComponent } from 'src/app/pagination/pagination.component';
import { SharedService } from 'src/app/shared.service';
import  {  FormGroup,  FormControl,  Validators, FormArray, FormArrayName, FormBuilder, FormControlName}  from  '@angular/forms';
import {ToastrService} from "ngx-toastr";
import { url } from 'inspector';
import { HttpResponse } from '@angular/common/http';




@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css'],

})
export class UploadFileComponent implements OnInit {
  page: Number =0;

  constructor( private fb : FormBuilder ,private baseService:BaseService, private sharedService:SharedService,private router:Router, private toastr: ToastrService,) { }


  uploadButtonText = "ADD FILES";

  errorMsg :string = "";

  file : FormControl;

  x :FormArray;
  userActivityList: any = [];
  pageLoader:boolean = true;
  loader:boolean = false;


  pageSize = 20;
  pageNum=1;
  total=0;
  uploads:any=[];
  password : FormControl;
  filesToUplaod:any = [];
  uploadOuterFileForm : FormGroup;
   urlLink : String ;

  uploadFileForm = FormGroup;

 uploadItems:FormArray;

 totalCount : number;

 documentToDelete : any;

 documentToDeleteName : string="";


  data : string = "";



 spinner:boolean = false;

 isSuperAdminUser = false;
 user:any 

 private companyWithNoGapAnalysis : string = "Kotak Wealth";

  showModal : boolean = false;
  @ViewChild('fileInput') fileInput: any;

  ngOnInit(): void {

    this.loadScript('../assets/myjs/info.js');
    this.user = localStorage.user? JSON.parse(localStorage.user):{};
    this.isSuperAdminUser = this.user.roles === "super_admin"; 
    this.uploadOuterFileForm = this.fb.group({
      uploadItems : this.fb.array ([ ])
     });

    this.getUploads(this.sharedService.getPageNumber());
    this.sharedService.lastPage = true;
  }
  public loadScript(url: string) {

    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }


  onFileDropped($event) {
    this.onSelectFile($event);
    this.showModal = true;
  }
  onSelectFile(event) {
    this.showModal = true;
        var filesAmount = event;
        for (let i = 0; i < filesAmount.length; i++) {
          let fItem:any  = ['file' , filesAmount[i]];



        (  <FormArray> this.uploadOuterFileForm.get('uploadItems')).push(this.addFileFormGroup(filesAmount[i]))


          this.filesToUplaod.push(fItem);

        }
        this.uploadButtonText = "ADD MORE FILES";



  }
  delete( ) {
    this.baseService.delete(environment.restPath+'file', this.documentToDelete.id).subscribe(
      (data) => {



        this.reload();
        this.toastr.success('Document Deleted  Successfully','',{
          positionClass:"toast-center-center",  timeOut: 4000
        });
        this.documentToDelete.clear();



      })


  }




  // getPageItems():void{
  //   this.baseService.getData(environment.restPath+'file/list/page/'+ this.pageNum+ '?thisUserOnly=true').subscribe(
  //     (data) => {
  //       this.total = data.result.totalCount;
  //       this.userActivityList = [];
  //       this.userActivityList = data.result.list;
  //     })
  // }


  download(url,mimeTye:string): any {
    this.baseService.download(url).subscribe( 
      (res: HttpResponse<Blob>) => this.downloadSuccessful(res, mimeTye)),
      (err) =>{
        console.log(err)
      }
  }
  
  downloadSuccessful(response: HttpResponse<Blob>, mimeTye:string): void {
    let fileName = 'DummyName.'+ mimeTye ;
    const contentDisposition = response.headers.get('Content-Disposition');
    if (contentDisposition) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
      const blob = new Blob([response.body], { type: this.sharedService.getMimeType(fileName.split(".")[1]) });
      const URL = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = URL;
      anchor.click();
    }
  }

  downloadPPT(upload){
    // window.location.href = environment.restPath+'file/download/ppt/'+ upload.id;
    const url = environment.restPath+'file/download/ppt/'+ upload.id;
    this.download(url, "ppt")
  }

  downloadOutput(upload){
    if(upload.outputDocumentLink && upload.outputDocumentLink.trim().length>0)
    {
      //window.location.href = environment.restPath+'file/download/reports/'+ upload.outputDocumentLink;
      const url = environment.restPath+'file/download/reports/'+ upload.outputDocumentLink;
      this.download(url, "xls")
    }
  }



  downloadPdf(upload){
    const url = environment.restPath+'file/download/source/'+ upload.sourceDocumentLink;
    this.download(url, 'pdf')

  }

  canDownloadPPT(upload:any):boolean{
   return this.isSuperAdminUser && (upload.documentFormat==='CONSOLIDATED')
  }



  fileToDelete(document){
    this.documentToDelete = document;
    this.documentToDeleteName = document.documentName;

  }
  // getdata(Name:string){
  //   this.data = Name;
  // }

  addFileFormGroup (fileItem :any): FormGroup{
    return this.fb.group({
      file : new FormControl(fileItem,Validators.required),
            password : new FormControl(null)
    })
  }

  reload(){
    this.getUploads(this.sharedService.getPageNumber());
  }


  getUploads(page)
  {
    this.urlLink = 'file/list/page/'+page+ '?thisUserOnly=true'
    this.baseService.getData(environment.restPath+this.urlLink).subscribe(
      (data) => {
           this.uploads = data.result.list;
           this.totalCount = data.result.totalCount;
       });
  }

  public greaterThan(nameLength: number, num: number) {
    return nameLength > num;
  }
  public removeDocFromPopUp(i : number){

    (  <FormArray> this.uploadOuterFileForm.get('uploadItems')).removeAt(i);
    this.fileInput.nativeElement.value = '';
    if((  <FormArray> this.uploadOuterFileForm.get('uploadItems')).length==0){
      this.errorMsg="";
      this.uploadButtonText = "ADD FILES";
    }

  }
  uploadFiles( event ){

    let isresponseOk = true;

    let formData = new FormData();

    this.uploadItems = this.uploadOuterFileForm.get('uploadItems') as FormArray;

    
    for (let group of this.uploadItems.controls ) {
      this.spinner = true;

      var g = <FormGroup>group;
      var file = g.controls.file.value;
      formData.append('file', file, file.name);
      var pass =  g.controls.password.value
      formData.append('password',pass!= null ? pass: ' ');

    }
    setTimeout(() => {
      if(isresponseOk){
        this.errorMsg="";
        this.showModal = false;
        this.spinner = false;
      this.toastr.success('Table will be populated once document is processed','',{
        positionClass:"toast-center-center",  timeOut: 3000
      });
      }

    }, 5000);

    this.baseService.fileUpload(formData)
    .subscribe(

      (data)=>{
        this.spinner = false;

        if(data.result==="InvalidPassword"){
          isresponseOk = false;
          this.showModal = true;
          this.errorMsg = "Invalid Password";
        }else if (data.result==="License Error")
        {
          isresponseOk = false;
          this.showModal = true;
          this.errorMsg = "License Error.Please contact MFIT!!!";
        }else if (data.result==="Invalid File Extension")
          {
            isresponseOk = false;
            this.showModal = true;
            this.errorMsg = "Invalid File Extension";
          }
        else{
          isresponseOk = true;
          this.closeModal()
          this.reload();
          this.toastr.success('Document Extracted Successfully','',{
          positionClass:"toast-center-center",  timeOut: 4000
        });
        }


      },error => {
        isresponseOk = false;
        this.errorMsg = "Something went wrong";
        this.showModal = true;
        if (this.uploadItems.controls.length==1){

    this.baseService.addUploadCrash(formData)
    .subscribe(

      (data)=>{
        console.log("addUploadCrashToUserActivity done")
        this.closeModal()
          this.reload();
      }
    );
     this.spinner = false;

  }
  
  else this.spinner = false;


      });


  }
  hasGapSheet(file){
    this.user = localStorage.user? JSON.parse(localStorage.user):{};
          
    if(this.user.companyName !== this.companyWithNoGapAnalysis){ 

      if(file.documentFormat.trim()==='CAMS' || file.documentFormat.trim()==='KARVY'){

        if(new Date(file.uploadTime).getTime()- new Date('07/23/2020').getTime()>0){
          return true;
        }

      }
    }
    return false
  }
  goToDashboard(upload){
    if(this.hasGapSheet(upload)){
      let documentJson = {id:upload._id, outputDocumentLink : upload.outputDocumentLink,sourceDocumentLink : upload.sourceDocumentLink  ,documentName:upload.documentName }
      this.router.navigate(['/dashboard'],
        { queryParams: { upload: JSON.stringify(documentJson) }}
      );

      this.sharedService.setGapSheetOriginCall('/upload')
    }
  }
  closeModal(){
    this.errorMsg="";
    this.showModal = false;
    this.spinner = false;
    // let filesInPopUp =
    for (let i=0; i< (  <FormArray> this.uploadOuterFileForm.get('uploadItems')).length ;i++) {
      this.removeDocFromPopUp(i);
      i--;
    }
  }
  goToUsageStatistics(){

    this.router.navigate(['/usageStatistics']);

  }


}
