import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseService } from 'src/app/base.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PaginationComponent } from 'src/app/pagination/pagination.component';
import { SharedService } from 'src/app/shared.service';
import  {  FormGroup,  FormControl,  Validators, FormArray, FormArrayName, FormBuilder, FormControlName}  from  '@angular/forms';
import {ToastrService} from "ngx-toastr";
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-upload-master',
  templateUrl: './upload-master.component.html',
  styleUrls: ['./upload-master.component.css'],

})
export class UploadMasterComponent implements OnInit {
  page: Number =0;

  constructor( 
    private fb : FormBuilder ,
    private baseService:BaseService, 
    private sharedService:SharedService,
    private router:Router, 
    private toastr: ToastrService,) { }


  uploadButtonText = "SELECT FILE";  
  fileName = "";
  errorMsg :string = "";
  file : File;  
  userActivityList: any = [];
  pageLoader:boolean = true;
  loader:boolean = false;
  pageSize = 20;
  pageNum=1;
  total=0;
  uploads:any=[];
  filesToUplaod:any;
  uploadOuterFileForm : FormGroup;
  urlLink : String ;
  uploadFileForm = FormGroup;
 uploadItems:FormArray;
 totalCount : number;
 documentToDelete : any;
 documentToDeleteName : string="";
  data : string = "";
 spinner:boolean = false;
 isSuperAdminUser = false;
 user:any 
 uploadType : string = "";
 barWidth : string = "0%";
 progress = 0;
 status :string = "";
 uploadTypeParameter:string = "";

  showModal : boolean = false;
  @ViewChild('fileInput') fileInput: any;

  ngOnInit(): void {

    this.loadScript('../assets/myjs/info.js');
    this.user = localStorage.user? JSON.parse(localStorage.user):{};
    this.isSuperAdminUser = this.user.roles === "super_admin"; 
    this.uploadOuterFileForm = this.fb.group({
      uploadItems : this.fb.array ([ ])
     });

    this.getUploads(this.sharedService.getPageNumber());
    this.sharedService.lastPage = true;
  }
  public loadScript(url: string) {

    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  selectUploadType(uploadType:any):void{
    this.uploadType = uploadType
    this.reload()
    this.status = "";
    this.fileName = "";
    this.file = null;
  }

  onFileDropped($event) {
    this.onSelectFile($event);
    this.showModal = true;
  }
  
  onSelectFile(event) {
    this.showModal = true;
    this.file = event.target.files[0];
    this.fileName = this.file.name;
    
  }


  delete( ) {
    this.baseService.delete(environment.restPath+'file', this.documentToDelete.id).subscribe(
      (data) => {
        this.reload();
        this.toastr.success('Document Deleted  Successfully','',{
          positionClass:"toast-center-center",  timeOut: 4000
        });
        this.documentToDelete.clear();
      })
  }

  download(upload): any {
    const url = environment.restPath+'file/download/reports/'+ upload.outputDocumentLink
    this.baseService.download(url).subscribe( (res) => this.downloadSuccessful(res));
  }
  downloadSuccessful(response: any): void {
    let fileName = 'errorSheet.xlsx';
    const contentDisposition = response.headers.get('Content-Disposition');
    if (contentDisposition) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
      const blob = new Blob([response.body], { type: 'application/ms-excel' });
      const URL = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = URL;
      anchor.click();
    }
  }

  downloadPPT(upload){
    window.location.href = environment.restPath+'file/download/ppt/'+ upload.id;
  }

  downloadOutput(upload){
    if(upload.outputDocumentLink && upload.outputDocumentLink.trim().length>0)
      //window.location.href = environment.restPath+'file/download/reports/'+ upload.outputDocumentLink;
      this.download(upload)

      
  }

  downloadPdf(upload){
    window.location.href = environment.restPath+'file/download/source/'+ upload.sourceDocumentLink;
  }

  canDownloadPPT(upload:any):boolean{
   return this.isSuperAdminUser && (upload.documentFormat==='CONSOLIDATED')
  }

  fileToDelete(document){
    this.documentToDelete = document;
    this.documentToDeleteName = document.documentName;

  }

  addFileFormGroup (fileItem :any): FormGroup{
    return this.fb.group({
      file : new FormControl(fileItem,Validators.required),
            password : new FormControl(null)
    })
  }

  reload(){
    this.getUploads(this.sharedService.getPageNumber());
  }


  getUploads(page)
  {
    this.urlLink = 'file/list/page/'+page+ '?thisUserOnly=true'
    this.baseService.getData(environment.restPath+this.urlLink).subscribe(
      (data) => {
           this.uploads = data.result.list;
           this.totalCount = data.result.totalCount;
       });
  }

  public greaterThan(nameLength: number, num: number) {
    return nameLength > num;
  }
  public removeDocFromPopUp(){

   
  }
  uploadFile( event ){

  let isresponseOk = true;

  let formData = new FormData();

  formData.append('file', this.file, this.file.name);

  switch(this.uploadType){
    case "SCHEME MASTER" : 
        this.uploadTypeParameter = "schemaster"  ;
        break;
    case "EXIT LOAD MASTER" : 
        this.uploadTypeParameter = "exitloadmaster"  ;
        break;
    case "ARN MASTER" : 
        this.uploadTypeParameter = "arnmaster"  ;
        break;
    case "AR RECOMMENDATION MASTER" : 
        this.uploadTypeParameter = "AR_recommendationMaster"  ;
        break;

  }

  if(this.uploadTypeParameter != ""){
    this.baseService.masterFileUpload(formData, this.uploadTypeParameter)  
      .pipe(map(
        event => {
          console.log(event.type);
          if (event.type === HttpEventType.UploadProgress) {                
            this.barWidth = Math.round((100 / (event.total || 0) * event.total)) +"%";
            this.status = "Uploading " + this.uploadType + ". Please wait...";
          } 
          else if (event.type === HttpEventType.ResponseHeader) {
            if(event.ok == true){
              this.barWidth = "0%";            
              this.status = this.uploadType + " uploaded to database successfully and ISIN index created.";
            }
            if(event.status == 417){
              this.status = this.uploadType + " uploaded to database successfully. ISIN index creation FAILED. Please create index manually.";
            }
          }
        }
      ))
      
      .subscribe(
      (data)=>{
          this.spinner = false;            
          isresponseOk = true;
          this.closeModal()
          this.reload();
      },
      error => {
        isresponseOk = false;
        this.errorMsg = "Something went wrong";
        this.showModal = true;
      });            
  }  
}


  closeModal(){
    this.errorMsg="";
    this.showModal = false;
    this.spinner = false;   
  }

  goToUsageStatistics(){
    this.router.navigate(['/usageStatistics']);
  }
}
