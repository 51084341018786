import {Component, OnInit, ViewChild} from '@angular/core';
import { BaseService } from 'src/app/base.service';
import { SharedService } from 'src/app/shared.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {PasswordValidation} from "../../validator";
import {ToastrService} from "ngx-toastr";
import { SearchComponent } from 'src/app/auto-complete/search/search.component';



@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  showMessage="";
  private email: any;
  private phone: any;
  private password: any;

  spinner = false;
  showCompanySelection: boolean = false;
  editSelectedUser: boolean = false;
  dormantUser;
  deActivatedBy;

  @ViewChild('userSearchComponent') userSearchComponent: SearchComponent;

  constructor(private fb:FormBuilder, 
    private baseService:BaseService, 
    private sharedService:SharedService,
    private router:Router,
    private toastService: ToastrService) { }
  page: Number= 0;
  name:any;

  submitted=false;
  companyName=""
  userCompany :any;
  users:any;
  userToEdit:any;
  userToToggleName = "";
  urlLink : string ;
  userToDeactivate ;
  companyPlaceHolder="Search";
  supervisorPlaceHolder="Search";
  userStatusString:string;
  userActivityList: any = [];
  adminUser:any ;
  roles : any = ["admin", "user" ];
  allowedRoles : any = ["admin", "user" ];
  @ViewChild('heroForm') form;
  userStatus:boolean;
  userName: string="";
  superVisorQueryFilter: string="";
  searchEnabled = false;
  downloadExcelActive = true;

  @ViewChild('user') userId;
  totalCount : number;

  supervisor =new FormControl('');

  userForm = new FormGroup({

    company: new FormControl('',Validators.required),
    name:new FormControl('',Validators.required),
    email:new FormControl('',[Validators.required,Validators.email]),
    roles:new FormControl('',Validators.required),
    supervisor:this.supervisor,
    phoneNumber:new FormControl('',Validators.required),
    password:new FormControl(''),

  })

  ngOnInit(): void {
    this.userForm = this.fb.group({
      'company': new FormControl('',Validators.required),
        'name':new FormControl('',Validators.required),
        'email':new FormControl('',Validators.required),
        'roles':new FormControl('',Validators.required),
        'supervisor':this.supervisor,
        'phoneNumber':new FormControl('',Validators.required),
        'password':new FormControl('',Validators.required),

      });

    this.getUsers(this.sharedService.getPageNumber());
    this.adminUser = localStorage.user? JSON.parse(localStorage.user):{};
  
    //this.userForm.controls.company.setValue("");

    if(this.adminUser.company != undefined)
    {
      this.onCompanySelected(this.adminUser.company);
    }
    this.showCompanySelection = this.adminUser.roles === "super_admin";

  }

  ngAfterViewInit() {
    // Now, this.userSearchComponent is available
    console.log(this.userSearchComponent); // This should log the component
  }

  clearSearch() {
    if (this.userSearchComponent) {
      this.userSearchComponent.clearSearch();  // Call the clearSearch method of SearchComponent
      this.searchEnabled = false;     
      this.reload();         // Disable search-related logic in the parent component
    } else {
      console.error('Search component is not initialized.');
    }
  }

  resetForm() {
    document.getElementById('add-new-form').style.display='none'
    this.editSelectedUser=false;
    this.userForm.reset();
    this.userForm.markAsPristine();
    this.userForm.markAsUntouched();
    this.submitted = false;
  }
  onCompanySelected(company)
  {
    // this.users["company"] = company.id;
    this.userForm.controls.company.setValue(company.id);
    this.superVisorQueryFilter = "company=" + company.id;
    this.companyName = company.name
  }
  getUsers(page)
  {
    this.urlLink = 'user/list/page/'+page
    this.baseService.getData(environment.restPath+this.urlLink).subscribe(
      (data) => {
        this.users = data.result.list;
        this.totalCount = data.result.totalCount;
      });
  }

  createUser(user){
    //in case of admin user creating, company selection is not triggered.Set company same as admin user company
    if(this.adminUser != undefined && this.adminUser.company != undefined)
    {
      user["company"] = this.adminUser.company.id;
    }
    if(user.supervisor==null || user.supervisor==''){
      delete  user.supervisor
    }

    // VAPT check to allow to create a user role for admin or user only. super_admin cannot be created through user management console
    if(user["roles"] =="admin" || user["roles"]=="user" ){
      this.create(user);
    }
    else {
      console.log("Invalid Role");
    }
  }

  create(user){

    const path = environment.restPath+'user/create';
    this.spinner = true;
    this.baseService.save(path, user).subscribe(
      (data)=>{
        this.userForm.controls.password.clearValidators();
        this.clearSearch();
        this.resetForm();
        this.spinner = false;
        document.getElementById('add-new-form').style.display='none';
        this.reload();
      },
      (error) => {
        // On error, show error message with ToastrService
        this.spinner = false;
        this.toastService.error('Failed to create user. Please try again.', '', {
          positionClass: "toast-bottom-center",
          timeOut: 4000
        });
        console.error('Error creating user:', error); // Log the error for debugging
      }
    );
  }
  onSupervisorSelected(user)
  {
    this.userForm.controls.supervisor.setValue(  user.id);
  }
  updateUser(id, user){
    const path = environment.restPath+'user/updateUser/' + id;

    let userUpdate = {};
    let changedProperties = this.getChangedProperties();
    changedProperties.forEach((name) => {
      userUpdate[name]=this.userForm.controls[name].value;
      if(name=='name'){
        userUpdate['displayName']=this.userForm.controls[name].value;
      }
      if(name=='password'){
        this.userForm.get('password').setValidators([Validators.required,PasswordValidation.PasswordRule])
        this.userForm.get('password').updateValueAndValidity();
      }
      if(name=='roles'){
        console.log(userUpdate['roles']);
        if(!this.allowedRoles.includes(userUpdate['roles'])){
            console.log("Invalid Role! User cannot be created.");
            return;
        }
      }
    });
    this.submitted = true;
    if (this.userForm.controls.supervisor.value) userUpdate['supervisor'] = this.userForm.controls.supervisor.value;
    if (this.userForm.controls.company.value){
       userUpdate['company'] = this.userForm.controls.company.value;
     if (this.companyName.trim()!='')  userUpdate['companyName'] = this.companyName;
    }
    console.log(userUpdate)
    this.spinner = true;
    if(!this.userForm.invalid) {
      this.baseService.update(path, userUpdate).subscribe(
        (data) => {
          this.userForm.controls.password.clearValidators()
          this.userToEdit = {};
          this.spinner = false;
          this.resetForm()
          this.clearSearch();
          document.getElementById('add-new-form').style.display = 'none';

        })
    }
  }
  private getChangedProperties(): string[] {
    let changedProperties = [];

    Object.keys(this.userForm.controls).forEach((name) => {
      let currentControl = this.userForm.controls[name];

      if (currentControl.dirty)
        changedProperties.push(name);
    });

    return changedProperties;
  }

  reload(){
    this.getUsers(this.sharedService.getPageNumber());
  }
  onSubmit(form)
  {
    if (this.editSelectedUser) {
      this.userForm.controls.password.clearValidators()
      this.updateUser(this.userToEdit.id, this.userToEdit);

    } else {
      this.userForm.controls.password.setValidators([Validators.required,PasswordValidation.PasswordRule])
      this.userForm.controls.password.updateValueAndValidity()

      let users = {};
      users["name"] = this.name = form.controls.name.value;
      users["email"] = this.email = form.controls.email.value;
      users["phoneNumber"] = this.phone = form.controls.phoneNumber.value;
      users["password"] = this.password = form.controls.password.value;
      users["roles"] = this.roles = form.controls.roles.value;
      users["supervisor"] = form.controls.supervisor.value;
      users["company"] = form.controls.company.value
      if(this.userForm.valid)
        this.createUser(users)
    }
  }

  userEdit(user) {
    this.editSelectedUser=true;
    if (user && user.company && user.company.name) this.companyPlaceHolder = user.company?.name
    if(user && user.supervisor&&user.supervisor.name) this.supervisorPlaceHolder = user.supervisor?.name
    this.userToEdit = user
    this.userForm.controls.company.setValue(user.company._id)
    this.userForm.controls.name.setValue(user.name)
    this.userForm.controls.email.setValue(user.email)
    this.userForm.controls.phoneNumber.setValue(user.phoneNumber)
    this.userForm.controls.roles.setValue(user.roles)
    this.userForm.controls.password.setValue(user.password)
    this.supervisor.setValue(user.supervisor.id)

  }

  userStatusToggle(user){
    this.userToToggleName = user.name;
    this.userToDeactivate = user;
    if (user.active){
      this.userStatusString ="deactivate";
      this.userStatus = false;
    }
    else {
      this.userStatusString ="activate";
      this.userStatus = true;

    }
  }
  onUserSelected(user)
  {
    this.userName = user.name;
    this.urlLink = 'user/list/filter/'+this.userName+'/'
    this.baseService.getData(environment.restPath+this.urlLink).subscribe(
      (data) => {
        this.searchEnabled = true;
        this.users = data.result.list;
        this.totalCount = data.result.totalCount;
      }

    );

  }
 
  deactivateUser(){

    this.urlLink = 'user/toggleUserStatus/'
    this.baseService.update(environment.restPath+this.urlLink+this.userToDeactivate._id,this.userStatus).subscribe(
      (data) => {
        if( !this.userStatus == false)
        {
          this.toastService.success('User Activated Successfully','',{
            positionClass:"toast-bottom-left",  timeOut: 4000
          });
        }
        else
        {
          this.toastService.success('User Deactivated Successfully','',{
            positionClass:"toast-bottom-left",  timeOut: 4000
          });
        }
        this.reload();

        this.userToDeactivate="";
      });

  }

  addNewUserPopUp() {
    document.getElementById('add-new-form').style.display='block'
    if(this.adminUser.company != undefined)
    {
      this.onCompanySelected(this.adminUser.company);
    }
    this.showCompanySelection = this.adminUser.roles === "super_admin";
  }

  downloadUsersList() {
    this.downloadExcelActive = false;
    this.urlLink = "user/downloadUsersList"
    // this.baseService.getData(environment.restPath+this.urlLink);
    if(this.adminUser.roles === "super_admin"){
      this.downloadExcelActive = true;
      return;
    }
    this.baseService.getFile(environment.restPath+this.urlLink).subscribe(blob => {
      // // Check if the blob size is 0, which could indicate no content
      // if (blob === undefined) {
      //   console.warn('No content available for download.');
      //   this.displayNoContentMessage(); // Display a message to the user
      //   this.downloadExcelActive = true;
      //   return;
      // }

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'MFIT-Users.zip'; // Adjust filename if needed
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      this.downloadExcelActive = true;
    }, error => {
      console.error('Error downloading ZIP file:', error);
      this.downloadExcelActive = true;
    });
  }


  displayNoContentMessage() {
    // Display a message to the user (use your preferred UI mechanism)
    alert('No data available for the selected company.');
}

  // downloadUsersList() {
  //   this.downloadExcelActive = false;
  //   this.urlLink = "user/downloadUsersList"
  //   // this.baseService.getData(environment.restPath+this.urlLink);
  //   this.baseService.getFile(environment.restPath+this.urlLink).subscribe(blob => {
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = 'MFIT-Users.zip'; // Adjust filename if needed
  //     document.body.appendChild(a);
  //     a.click();
  //     a.remove();
  //     window.URL.revokeObjectURL(url);
  //     this.downloadExcelActive = true;
  //   }, error => {
  //     console.error('Error downloading ZIP file:', error);
  //     this.downloadExcelActive = true;
  //   });
    
  // }

  onStatusDormantClick(user): void {
    document.getElementById('confirmation-dialog').style.display='block';
    const loggedUser = localStorage.user? JSON.parse(localStorage.user):{};
    if(loggedUser){
      this.deActivatedBy = loggedUser.id;
      this.dormantUser = user;
    }
  }

  onConfirm(): void {
    document.getElementById('confirmation-dialog').style.display='none';
    this.dormantUser.deActivatedBy = this.deActivatedBy;
    this.urlLink = 'user/setToInActive/'
    this.baseService.update(environment.restPath+this.urlLink+this.dormantUser._id, this.dormantUser).subscribe(
      (data) => {
          this.toastService.success('User Inactivated Successfully','',{
            positionClass:"toast-bottom-left",  timeOut: 4000
          });
        this.reload();

        this.dormantUser = "";
      });


  
  }

  onCancel(): void {
    document.getElementById('confirmation-dialog').style.display='none';
  }
}
