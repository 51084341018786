import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import { Subscription } from 'rxjs/Subscription';
import {SearchService} from "../search.service";

@Component({
  selector: 'app-search',
  inputs:[],
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})

export class SearchComponent implements OnInit {

  @Input()
  collectionType: string;

  show: boolean;

  @Input()
  searchField: string;

  @Input()
  placeholder: string ='Search';


  @Input()
  filters:string;

  valueChangeObserver: Subscription;

  @Input()
  controlId:string;


  @Output() onSelect = new EventEmitter();


  results: any[] = [];
  myGroup = new FormGroup({
    queryField: new FormControl()
  });

  constructor(private _searchService: SearchService) { }

  ngOnInit() {
    this.subscribeToValueChanges();
  }

  private subscribeToValueChanges() {
    this.valueChangeObserver = this.myGroup.controls.queryField.valueChanges
      .debounceTime(100)
      .distinctUntilChanged()
      .subscribe(queryField => this._searchService.search(this.collectionType, this.searchField, queryField, this.filters)
        .subscribe(response =>
          {
            this.show = true;
            this.results = response.results;
          }

        ));
  }

  onSearchResultSelect(result)
  {
    this.valueChangeObserver.unsubscribe();
    this.myGroup.controls.queryField.setValue(result.name);
    this.onSelect.emit(result);
    this.subscribeToValueChanges();
    this.show= false;
  }

  clearSearch() {
    this.myGroup.controls.queryField.setValue('Search');  
    this.results = [];                              
    this.show = false;                              
    this.onSelect.emit('');                         
  }

  closePopUp(){
    this.show=false
    this.myGroup.controls.queryField.setValue('')
    this.onSelect.emit('');

  }

}
