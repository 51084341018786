import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseService } from 'src/app/base.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import {PaginationComponent} from "../../pagination/pagination.component";
import {PaginationModule} from "../../pagination/pagination.module";
import { SharedService } from 'src/app/shared.service';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-approved-files',
  templateUrl: './approved-files.component.html',
  styleUrls: ['./approved-files.component.css']
})
export class ApprovedFilesComponent implements OnInit {
  private pageNum: string;
  private total: any;
  private userActivityList: any[];

  constructor(private baseService:BaseService,private router:Router,private sharedService:SharedService,private fb:FormBuilder,private toastr: ToastrService) { }
  urlLink : String ;
  totalCount : number;
  uploads:any;
  documenttoDelete  ;
  approvedPageNum=1;
  data : string = "";
  errorMsg:string ="";
  submitted:boolean = false;
  toggle=true;
  isFilterSet :boolean = false;
  dateFilterForm: FormGroup;


  @ViewChild(PaginationComponent ) page_comp: PaginationComponent ;

  ngOnInit(): void {
    this.getUploads(this.sharedService.getPageNumber());
    this.dateFilterForm = this.fb.group({
      startDate:new FormControl('',Validators.required),
      endDate:new FormControl('',Validators.required),
    });
  }
  reload(){
    if(!this.isFilterSet)
      this.getUploads(this.sharedService.getPageNumber());
    else
      this.getFilteredFiles(this.sharedService.getPageNumber());

  }
  delete() {
    this.baseService.delete(environment.restPath+'file/approve',this.documenttoDelete.id).subscribe(
      (data) => {
        this.reload();
        this.toastr.success('Document Deleted  Successfully','',{
          positionClass:"toast-top-left",  timeOut: 4000
        });
      })
      }

  gotoAlready() {
    this.router.navigate(['/approvedUploads']);
  }
  gotoYettobe() {
    this.router.navigate(['/yetToBeApprovedUploads']);
  }

  // downloadOutput(upload){
  //   if(upload.outputDocumentLink && upload.outputDocumentLink.trim().length>0)
  //     window.location.href = environment.restPath+'file/download/reports/'+ upload.outputDocumentLink;
  // }

  // downloadpdf(upload){
  //   window.location.href = environment.restPath+'file/download/source/'+ upload.sourceDocumentLink;
  // }

  downloadOutput(upload){
    if(upload.outputDocumentLink && upload.outputDocumentLink.trim().length>0)
    {
      const url = environment.restPath+'file/download/reports/'+ upload.outputDocumentLink;
      this.download(url, "xls")
    }
 }

 downloadPdf(upload){
    const url = environment.restPath+'file/download/source/'+ upload.sourceDocumentLink;
    this.download(url, 'pdf')

  }

  download(url,mimeTye:string): any {
    this.baseService.download(url).subscribe( 
      (res: HttpResponse<Blob>) => this.downloadSuccessful(res, mimeTye)),
      (err) =>{
        console.log(err)
      }
    }

  downloadSuccessful(response: HttpResponse<Blob>, mimeTye:string): void {
    let fileName = 'DummyName.'+ mimeTye ;
    const contentDisposition = response.headers.get('Content-Disposition');
    if (contentDisposition) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
      const blob = new Blob([response.body], { type: this.sharedService.getMimeType(fileName.split(".")[1]) });
      const URL = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = URL;
      anchor.click();
    }
  }

  fileToDelete(dFileName:string){
    this.documenttoDelete = dFileName;
  }
  getdata(Name:string){
    this.data = Name;
  }
  getUploads(page)
  {
    this.urlLink = 'file/approved/list/page/'+page;
    this.baseService.getData(environment.restPath+this.urlLink).subscribe(
      (data) => {
           this.uploads = data.result.list;
           this.totalCount = data.result.totalCount;
       });
  }
  onFilterSubmit(){
    this.submitted = true
    this.errorMsg="";
    let startDate =new Date( this.dateFilterForm.controls.startDate.value);
    let endDate = new Date( this.dateFilterForm.controls.endDate.value);

    if(this.dateFilterForm.invalid && this.submitted){
      this.errorMsg ="Please enter date"
    }
    else if(endDate.getTime() - startDate.getTime()<0){
      this.errorMsg="Invalid End Date"
    }
    else {
      this.sharedService.setPageNumber(1)
      this.getFilteredFiles(this.sharedService.getPageNumber())
      this.page_comp.ngOnInit()
      document.getElementById( 'filter-pop-up-tab').style.display='none'

    }
  }

  getFilteredFiles(page){
    this.urlLink = 'filter/approved/list/'+page+'/startDate/'+this.dateFilterForm.controls.startDate.value+'/endDate/'+this.dateFilterForm.controls.endDate.value;

    this.baseService.getData(environment.restPath+this.urlLink).subscribe(
      (data) => {
        this.uploads = data.result.list;
        this.totalCount = data.result.totalCount;
        this.isFilterSet = true;
      });
  }
  cancelFilter(){
    this.isFilterSet = false;
    this.toggle=true;
  this.sharedService.setPageNumber(1);
  this.reload();
  this.page_comp.ngOnInit()
  document.getElementById( 'filter-pop-up-tab').style.display='none'
  }
  clearFilter(){
    this.submitted=false;
    this.dateFilterForm.controls.startDate.reset();
    this.dateFilterForm.controls.endDate.reset();

   
  }
  clear(){
    this.toggle=true;
    this.isFilterSet = false
    this.reload();
    this.dateFilterForm.controls.startDate.reset();
    this.dateFilterForm.controls.endDate.reset();
 
  }
  filter(){
    this.toggle=!this.toggle;
      document.getElementById('filter-pop-up-tab').style.display='block';
  }



}
