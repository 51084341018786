import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import {environment} from '../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class BaseService {
constructor(private http: HttpClient) {
}

  getData(path:string) {
    return this.http.get<any>(path)
      .map(response =>response);
  }

   save(url:string, data:any) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
       return this.http.post<any>(url, data, { headers: headers })
       .map(response => response);
   }

   delete(url:string, id:any) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
       return this.http.delete<any>(url+ "/" + id,  { headers: headers })
       .map(response => response);
   }

   download(url:string) {
    const headers = new HttpHeaders();
    headers.set('Accept', 'application/json');
    headers.delete('Content-Type'); // mandate for accepting binary content
    const requestOptions: Object = {
      /* other options here */
      responseType: 'blob' as 'json',
      observe: 'response' as 'body',
      header: headers,
    };
    return this.http.get(url, requestOptions)
   }

   update(path:string, data:any){
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
       return this.http.put<any>(path, data, { headers: headers })
       .map(response => response);
   }

   fileUpload(formData:any){
       let url:string = environment.restPath+ 'file/upload';
       return this.http.post<any>(url,formData).map(res=>res);
    }
    addUploadCrash(formData:any){
      let url:string = environment.restPath+ 'file/addUploadCrash';
      return this.http.post<any>(url,formData).map(res=>res);
   }
  ocrFileUpload(formData:any){
    let url:string = environment.restPath+ 'file/ocr-upload';
    return this.http.post<any>(url,formData).map(res=>res);
  }

  splitPDF(formData:any){
    let url:string = environment.restPath+ 'pdf/split';
    return this.http.post<any>(url,formData).map(res=>res);
  }
  masterFileUpload(formData:any, uploadType : string){
    let url:string = environment.restPath + uploadType + '/upload';
    return this.http.post<any>(url,formData,{ reportProgress: true, observe:"events" }).map(res=>res);
 }

 getFile(url: string): Observable<Blob> {
  return this.http.get(url, { responseType: 'blob' });
}
}
  