import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/base.service';
import { SharedService } from 'src/app/shared.service';
import { environment } from 'src/environments/environment';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { forEachComment } from "tslint";

@Component({
  selector: 'app-company-management',
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.css']
})
export class CompanyManagementComponent implements OnInit {
  searchEnabled: boolean;

  constructor(private fb: FormBuilder, private baseService: BaseService, private sharedService: SharedService) { }
  page: Number = 0;
  submitted = false;
  companyList: any;
  checkboxes: any;
  expanded = false;
  companyToToggleName = "";
  outputFormatList = [];
  pdfParsingFormats =[];
  consolidationFormats =[];


  urlLink: String;

  totalCount: number;
  pdfParsingReportsControl: FormControl;
  consolidationReportsControl: FormControl;
  billingPlan: FormControl;
  pricingPlans: any = ['Monthly', 'Standard']
  companyForm: FormGroup;
  // featuresGroup: FormGroup
  // documentCategories: FormGroup
  companyWiseReports: any = [];
  featuresNotSelected: boolean = false;
  documentsNotSelected: boolean = false;
  isUpdateCompany = false;
  companyToBeUpdated: any;
  macAddress: any = [' ']
  features: any = []
  documentCategoriesArr: any = []
  documentSubCategoriesArr: any = []


  ngOnInit(): void {
    this.isUpdateCompany = false;
    this.pdfParsingReportsControl = new FormControl([])
    this.consolidationReportsControl = new FormControl([])
    
  
    this.billingPlan = new FormControl('', Validators.required)
    // this.featuresGroup = this.fb.group({
    //   'gapAnalysis': new FormControl(''),
    //   'pdfParsing': new FormControl(''),
    //   'ocrParsing': new FormControl(''),
    //   'consolidation': new FormControl(''),
    //   'enrichment': new FormControl('')
    // }),
    //   this.documentCategories = this.fb.group({
    //     'INVESTMENTS': new FormControl(''),
    //     'BANKS': new FormControl(''),
    //     'CREDITCARDS': new FormControl(''),
    //     'BANKS_CURRENTACCOUNT': new FormControl(''),
    //     'INTERNATIONAL_INVESTMENTS': new FormControl('')
    //   }),
      this.companyForm = this.fb.group({
        'name': new FormControl('', Validators.required),
        'agreementDate': new FormControl('', Validators.required),
        'startDate': new FormControl('', Validators.required),
        'endDate': new FormControl('', Validators.required),
        'documentRetentionPeriod': new FormControl('', Validators.required),
        'pdfParsingReportConfig': this.pdfParsingReportsControl,
        'consolidationReportConfig': this.consolidationReportsControl,
        'documentsPerMonth': new FormControl('', Validators.required),
        'noOfUsers': new FormControl('', Validators.required),
        'macAddresses': new FormControl(''),
        'features': new FormControl('', Validators.required),
        'documentCategories': new FormControl('', Validators.required),
        'documentSubCategories': new FormControl(''),
        'pricing': new FormControl(''),
        'billingPlan': this.billingPlan,
        'activePeriod' :  new FormControl('0', Validators.required),
      })

    this.getCompanies(this.sharedService.getPageNumber());
    this.getOutputFormats()
    this.getFeatures()
    this.getDocumentCategories();
    this.getDocumentSubCategories();
  }
  getOutputFormats() {
    let outputFormatLink = 'reportConfig/output_formats/list'
    this.baseService.getData(environment.restPath + outputFormatLink).subscribe(
      (data) => {
        this.outputFormatList = data.featuresToTemplates;
        this.pdfParsingFormats = this.makeJsonArrayFromList(this.outputFormatList['pdfParsing'])
        this.consolidationFormats = this.makeJsonArrayFromList(this.outputFormatList['consolidation'])

      });
  }

  getFeatures() {
    this.features = [];
    this.baseService.getData(environment.restPath + 'license/features').subscribe(
      (data) => {
        this.features = this.makeJsonArrayFromList(data.list)
      });
  }

  makeJsonArrayFromList(data: any[]): any[] {

    let arr: any = []

    if (data){  
    data.forEach((item, index) => {
      let option = {  name: item };
      arr.push(option);
    });
  }
    return arr;


  }

  makeListFromJsonArray(data: any[]): any[] {

    let arr = []
    if  (data)
    arr = data.map(d=>d.name)
    return arr;


  }
  getDocumentCategories() {

    this.documentCategoriesArr = [];

    this.baseService.getData(environment.restPath + 'license/documentCategories').subscribe(
      (data) => {
        this.documentCategoriesArr = this.makeJsonArrayFromList(data.list);
      }
    );
  }
  getDocumentSubCategories() {

    this.documentSubCategoriesArr = [];

    this.baseService.getData(environment.restPath + 'license/documentSubCategories').subscribe(
      (data) => {
        this.documentSubCategoriesArr = this.makeJsonArrayFromList(data.list);
      }
    );
  }
  getCompanies(page) {

    this.companyList = {}
    this.urlLink = 'organization/v1/list/page/' + page
    this.baseService.getData(environment.restPath + this.urlLink).subscribe(
      (data) => {
        this.companyList = data.result.list;
        this.totalCount = data.result.totalCount;
        let companyWiseReportLink = 'reportConfig/list'
        this.baseService.getData(environment.restPath + companyWiseReportLink).subscribe(
          (data) => {
            this.companyWiseReports = {}
            this.companyWiseReports = data.list;
            this.mergeCompanyWithReportsList()
            console.log(this.companyList)

          });
      });



  }


  reload() {
    this.getCompanies(this.sharedService.getPageNumber());
  }
  onSubmit() {
    if (!this.JsonArrayContains(this.companyForm.get('features').value,'consolidation')) {
      this.companyForm.controls.consolidationReportConfig.setValidators(Validators.required)
    }
    if (!this.JsonArrayContains(this.companyForm.get('features').value,'pdfParsing') ) {
      this.companyForm.controls.pdfParsingReportConfig.setValidators(Validators.required)
    }

    if (this.isUpdateCompany) {
      this.updateCompany()
    }
    else {

      if (this.companyForm.invalid) {
        this.submitted = true;
      }
      else if (this.featuresNotSelected == false && this.documentsNotSelected == false) {

        let newCompanyData = this.createCompanyJson();
        let companyId;

        let newCompanyLicense = {};

        let newCompanyLink = environment.restPath + 'organization/create'
        this.baseService.save(newCompanyLink, newCompanyData).subscribe(
          (data) => {

            let newCompanyLicenseLink = environment.restPath + 'license/createLicense'
            newCompanyLicense = this.createCompanyLicenseJson(data.result.companyId)
            this.baseService.save(newCompanyLicenseLink, newCompanyLicense).subscribe(
              (data) => {

              });
            let features = this.getMultiSelectValues('features')
            if (features.toString().includes('pdfParsing'))
              this.createCompanyReportConfig(data.result.companyId, 'pdfParsing')
            if (features.toString().includes('consolidation'))
              this.createCompanyReportConfig(data.result.companyId, 'consolidation')


            document.getElementById('company-register-form').style.display = 'none'
            this.clearForm()
            this.submitted = false;
            this.reload()
          });



      }
    }
  }

  dropBox() {
    document.getElementById('drop_box').style.display = "none";
  }


  companyData(company) {
    this.companyToToggleName = company.name;
  }
  downloadApiKey(apiKey) {

    const file = new window.Blob([apiKey]);

    const downloadAncher = document.createElement("a");
    downloadAncher.style.display = "none";

    const fileURL = URL.createObjectURL(file);
    downloadAncher.href = fileURL;
    downloadAncher.download = 'apiKey.txt';
    downloadAncher.click();
  }
  mergeCompanyWithReportsList() {
    for (let i = 0; i < this.companyList.length; i++) {
      let company = this.companyList[i];
      for (let j = 0; j < this.companyWiseReports.length; j++) {

        if (company.company.id === this.companyWiseReports[j].company.id) {
          if (this.companyList[i]['reports'])
            this.companyList[i]['reports'] += ',' + this.companyWiseReports[j].output_formats.toString();
          else
            this.companyList[i]['reports'] = this.companyWiseReports[j].output_formats.toString();
          if (this.companyWiseReports[j].feature == 'pdfParsing' && !this.companyList[i]['pdfParsingReportId'])
            this.companyList[i]['pdfParsingReportId'] = this.companyWiseReports[j].id;
          if (this.companyWiseReports[j].feature == 'consolidation' && !this.companyList[i]['consolidationReportId'])
            this.companyList[i]['consolidationReportId'] = this.companyWiseReports[j].id;
        }

      }
    }

  }
  getMultiSelectValues(groupName): string[] {
    let values = this.makeListFromJsonArray(this.companyForm.get(groupName).value)

    return values;
  }
  // anySelectedFromGroup(groupName): boolean {

  //   const group = this.companyForm.get(groupName) as FormGroup
  //   for (let key of Object.keys(group.controls)) {

  //     if (group.controls[key].value == true) {
  //       return true
  //     }

  //   }

  //   return false
  // }
  onCompanySelected(user) {
    let companyId = user.id;
    if (companyId) {
      this.urlLink = 'organization/v1/list/filter/' + companyId
      this.baseService.getData(environment.restPath + this.urlLink).subscribe(
        (data) => {
          this.searchEnabled = true;
          this.companyList = data.result.list;
          this.totalCount = data.result.totalCount;
          let companyWiseReportLink = 'reportConfig/list'
          this.baseService.getData(environment.restPath + companyWiseReportLink).subscribe(
            (data) => {
              this.companyWiseReports = data.list;
              this.mergeCompanyWithReportsList()


            });
        }
      );

    }
  }
  clearSearch() {
    this.searchEnabled = false;
    this.submitted = false;
    this.reload();
  }
  editCompany(company: any) {
    document.getElementById('company-register-form').style.display = 'block'
    this.companyForm.controls.name.setValue(company.company['name'])
    this.companyForm.controls.agreementDate.setValue(company.company['agreementDate'])
    this.companyForm.controls.startDate.setValue(company['startDate'])
    this.companyForm.controls.endDate.setValue(company['endDate'])
    this.companyForm.controls.documentRetentionPeriod.setValue(company.company['documentRetentionPeriod'])
    this.companyForm.controls.activePeriod.setValue(company.company['activePeriod'])
    this.companyForm.controls.documentsPerMonth.setValue(company['documentsPerMonth'])
    this.companyForm.controls.noOfUsers.setValue(company['noOfUsers'])
    this.companyForm.controls.macAddresses.setValue(company['macAddresses'])
    if (company['reports']){ 
    const outputReports = this.makeJsonArrayFromList( company['reports'].split(','))
    this.pdfParsingReportsControl.setValue( this.getCommonListFromLists(this.pdfParsingFormats, outputReports))
    this.consolidationReportsControl.setValue(this.getCommonListFromLists(this.consolidationFormats, outputReports))
  } 
    this.companyForm.controls.features.setValue((this.makeJsonArrayFromList(company['features'])))
    this.companyForm.controls.documentCategories.setValue(this.makeJsonArrayFromList(company['documentCategories']))
    this.companyForm.controls.documentSubCategories.setValue(this.makeJsonArrayFromList(company['documentSubCategories']))
    this.companyForm.controls.billingPlan.setValue(company.company['billingPlan'])
    this.companyForm.controls.pricing.setValue(company.company['pricing'])
    this.isUpdateCompany = true
    this.companyToBeUpdated = company;


  }
  // setChildGroupFormCheckBoxes(groupName, array: any = []) {

  //   const group = this.companyForm.get(groupName) as FormGroup
  //   for (let key of Object.keys(group.controls)) {

  //     if (array.includes(key)) {
  //       group.controls[key].setValue(true)
  //     }

  //   }

  // }

  getCommonListFromLists(list1:any,list2:any):any[]{


   return list1.filter(l1 => list2.some(l2 => l1.name === l2.name ));


  }

  updateCompany() {

    let companyUpdate = {};
    let changedProperties = this.getChangedProperties();
    changedProperties.forEach((name) => {
      companyUpdate[name] = this.companyForm.controls[name].value;
    });
    if (companyUpdate.hasOwnProperty('name') || companyUpdate.hasOwnProperty('agreementDate') || companyUpdate.hasOwnProperty('startDate')
      || companyUpdate.hasOwnProperty('documentRetentionPeriod') || companyUpdate.hasOwnProperty('billingPlan') || companyUpdate.hasOwnProperty('pricing')
      || companyUpdate.hasOwnProperty('activePeriod')
    ) {
      let updateJson = this.getCommonKeysFromJSONS(companyUpdate, this.createCompanyJson())
      const path = environment.restPath + 'organization/update/' + this.companyToBeUpdated.company.id;
      this.baseService.update(path, updateJson).subscribe(
        (data) => {
          // console.log('companyData Updated')
          console.log(updateJson)
          this.reload()


        })
    }
    if (companyUpdate.hasOwnProperty('features') ||companyUpdate.hasOwnProperty('documentSubCategories') || companyUpdate.hasOwnProperty('documentCategories') || companyUpdate.hasOwnProperty('startDate')
      || companyUpdate.hasOwnProperty('endDate') || companyUpdate.hasOwnProperty('noOfUsers') || companyUpdate.hasOwnProperty('documentsPerMonth')
    ) {
      let updateJson = this.getCommonKeysFromJSONS(companyUpdate, this.createCompanyLicenseJson(this.companyToBeUpdated.id))
      updateJson['macAddresses'] = this.macAddress
      const path = environment.restPath + 'license/update/' + this.companyToBeUpdated.id;
      this.baseService.update(path, updateJson).subscribe(
        (data) => {
          console.log('license form updated')
          this.reload()

        })
    }
    if (companyUpdate.hasOwnProperty('features') || companyUpdate.hasOwnProperty('pdfParsingReportConfig') || companyUpdate.hasOwnProperty('consolidationReportConfig')) {
      if (!this.companyToBeUpdated['features'].toString().includes('consolidation') && companyUpdate.hasOwnProperty('features') && companyUpdate['features']['consolidation']) {
        this.createCompanyReportConfig(this.companyToBeUpdated.company.id, 'consolidation')
      } if (!this.companyToBeUpdated['features'].toString().includes('pdfParsing') && companyUpdate.hasOwnProperty('features') && companyUpdate['features']['pdfParsing']) {
        this.createCompanyReportConfig(this.companyToBeUpdated.company.id, 'pdfParsing')
      }
      this.updateCompanyReportConfig(this.companyToBeUpdated, companyUpdate)
    }
    this.clearForm()
    this.companyToBeUpdated = {}



  }
  clearForm() {
    document.getElementById('company-register-form').style.display = 'none'
    this.isUpdateCompany = false;
    this.companyForm.controls.consolidationReportConfig.clearValidators()
    this.companyForm.controls.pdfParsingReportConfig.clearValidators()
    this.companyForm.reset();
   
  }
  
  private getChangedProperties(): string[] {
    let changedProperties = [];

    Object.keys(this.companyForm.controls).forEach((name) => {
      let currentControl = this.companyForm.controls[name];

      if (currentControl.dirty)
        changedProperties.push(name);
    });

    return changedProperties;
  }

  JsonArrayContains(arr:any[],str:any):boolean{
    if(arr){
    const result = arr.filter((d: any) => d.name === str);
    return result && result.length >= 1;
  }

  return false;

  }

  updateCompanyReportConfig(company, companyUpdate) {
    let features = this.getMultiSelectValues('features')
    let newReportConfig = {};
    if (features.toString().includes('pdfParsing') && companyUpdate.hasOwnProperty('pdfParsingReportConfig')) {
      let newReportConfigLink = environment.restPath + 'reportConfig/updateReportConfig/' + company.pdfParsingReportId
      newReportConfig = this.createReportConfigJson('pdfParsing', 'pdfParsingReportConfig')
      newReportConfig['company'] = company.company
      this.baseService.update(newReportConfigLink, newReportConfig).subscribe(
        (data) => {
          console.log('Report Congig Updated')
          this.reload()


        });
    }
    if (features.toString().includes('consolidation') && companyUpdate.hasOwnProperty('consolidationReportConfig')) {
      let newReportConfigLink = environment.restPath + 'reportConfig/updateReportConfig/' + company.consolidationReportId
      newReportConfig = this.createReportConfigJson('consolidation', 'consolidationReportConfig')
      newReportConfig['company'] = company.company
      this.baseService.update(newReportConfigLink, newReportConfig).subscribe(
        (data) => {
          this.reload()

          console.log('Report Congig Updated')

        });
    }
  }


  createCompanyReportConfig(id, type) {
    let newReportConfigLink = environment.restPath + 'reportConfig/create'
    let newReportConfig = {};
    if (type.includes('pdfParsing')) {
      newReportConfig = this.createReportConfigJson('pdfParsing', 'pdfParsingReportConfig')
    }
    else if (type.includes('consolidation')) {
      newReportConfig = this.createReportConfigJson('consolidation', 'consolidationReportConfig')
    }
    newReportConfig['company'] = id
    this.baseService.save(newReportConfigLink, newReportConfig).subscribe(
      (data) => {

        console.log('newRport Config created')
        this.reload()

      });

  }
  createReportConfigJson(feature, type): Object {
    let json = {}

    json['feature'] = feature;
    json['output_formats'] = this.makeListFromJsonArray(this.companyForm.controls[type].value)
    return json

  }

  createCompanyLicenseJson(id): Object {
    let json = {};
    json['features'] = this.getMultiSelectValues('features')
    json['documentCategories'] = this.getMultiSelectValues('documentCategories')
    json['documentSubCategories'] = this.getMultiSelectValues('documentSubCategories')
    json['startDate'] = this.companyForm.controls.startDate.value
    json['endDate'] = this.companyForm.controls.endDate.value
    json['noOfUsers'] = this.companyForm.controls.noOfUsers.value
    json['macAddresses'] = this.macAddress
    json['documentsPerMonth'] = this.companyForm.controls.documentsPerMonth.value
    json['company'] = id

    return json

  }
  createCompanyJson(): Object {
    let json = {};
    json['displayName'] = this.companyForm.controls.name.value
    json['name'] = this.companyForm.controls.name.value
    json['agreementDate'] = this.companyForm.controls.agreementDate.value
    json['billingStartDate'] = this.companyForm.controls.startDate.value
    json['documentRetentionPeriod'] = this.companyForm.controls.documentRetentionPeriod.value
    json['billingPlan'] = this.companyForm.controls.billingPlan.value.toString()
    json['pricing'] = this.companyForm.controls.pricing.value

    return json
  }

  getCommonKeysFromJSONS(changedJson, originalJson): Object {
    let json = {};
    for (let key of Object.keys(changedJson)) {
      if (originalJson.hasOwnProperty(key)) {
        json[key] = originalJson[key]
        if (key === 'name') {
          json['displayName'] = originalJson[key]

        }
      }
      if (key === 'startDate' && originalJson.hasOwnProperty('billingStartDate')) {
        json['billingStartDate'] = changedJson[key]

      }
      if(key === 'activePeriod'){
        json['activePeriod'] = changedJson[key]
      }
    }




    return json;
  }

  

}
