<div class="main_left_container">
  <div class="middle_container">
    <div class="recent_title">
      <span class="txt">Company Management Console</span>
    </div>
    <div class="box_search">

      <app-search class="search" (onSelect)="onCompanySelected($event)" collectionType="ORGANIZATION" searchField="name" controlId="company"></app-search>


      <button [disabled]="!searchEnabled" [ngClass]="searchEnabled?'clear-search-enabled':'clear-search-disabled'" type="button" (click)="clearSearch()" >
        <div class="both_tab">
          <i class="material-icons close-icon" id="filter-icon">close</i>
          <span class="txt_filter">Clear</span>
        </div>
      </button>
      <button class="add-new-btn" onclick="document.getElementById('company-register-form').style.display='block'">
        <div class="btn1">
          <i class="material-icons"
             style="width: 24px;height:24px;color:#fff;margin-left:2%;margin-top: -1%!important ;">add</i>
          <span class="addnew">Add New</span>
        </div>
      </button>

    </div>
  </div>

  <!-- Register Pop-up form Starts -->

  <form [formGroup]="companyForm">
    <div id="company-register-form" class="company-register-modal">
      <div class="usages-statistics">
        <div class="box arrow-top">
          <div class="company">
            <div class="label-box1">
              <span class="main">Company Name</span><span class="required">*</span><br>
              <span class="main_text">Enter client’s company name </span>
            </div>
            <input type="text"  required formControlName="name" name="cname" placeholder="Company Name"
                   class="input_box">
          </div>
          <div style="text-align:left;max-width: 20%;margin-left:28% !important;" *ngIf="companyForm.controls.name.errors && submitted">
                        <span class="text_danger" >Company name is required </span>
          </div>

          <div class="company">
            <div class="label-box1">
              <span class="main">Agreement Date</span><span class="required">*</span><br>
              <span class="main_tex75%t">Agreement Date</span>
            </div>

            <input type="text" id="agreement" formControlName="agreementDate"  required name="agreement"
                   placeholder="Agreement Date" onfocus="(this.type='date')" class="input_box1">

          </div>
          <div style="text-align:left;max-width: 20%;margin-left:28% !important;" *ngIf="companyForm.controls.agreementDate.errors && submitted">
                        <span class="text_danger">Agreement Date is required </span>
          </div>

          <div class="company3">
            <div class="label-box1">
              <span class="main">Start-End Date</span><span class="required">*</span><br>
              <span class="main_text">Select start date and end date </span>
            </div>
            <div class="start-end-date-both">
              <input type="text" required name="date" formControlName="startDate" placeholder="Start Date"
                     onfocus="(this.type='date')" class="input_box2 input-start">




              <input type="text" required name="date" formControlName="endDate" placeholder="End Date"
                     onfocus="(this.type='date')" class="input_box2  input-end">



            </div>
            <div class="start-end-date-both" *ngIf="(companyForm.controls.startDate.errors || companyForm.controls.endDate.errors )&& submitted">
              <div style="text-align:left;max-width: 20%;display: inline-block; margin-left:8% !important;" *ngIf="companyForm.controls.startDate.errors && submitted">
                <span class="text_danger">Start Date is required </span>
              </div>

              <div style="text-align:left; display: inline-block; max-width: 20%;margin-left:35% !important;" *ngIf="companyForm.controls.endDate.errors && submitted">
                <span class="text_danger" >End Date is required </span>
              </div>
            </div>

            </div>
          <br>
          <div class="company1">
            <div class="label-box1">
              <span class="main">Retention Period</span><span class="required">*</span><br>
              <span class="main_text">Retention Period</span>
            </div>
            <input id="no-of-text" type="text" formControlName="documentRetentionPeriod" required name="doc"
                   placeholder="Number of days of rentention" class="right_box1">
          </div>
          <div style="text-align:left;max-width: 20%;margin-left:28% !important; margin: 0.5% 0;" *ngIf="companyForm.controls.documentRetentionPeriod.errors && submitted">
            <span class="text_danger" >Retention Period is required </span>
          </div>
          <br>
          <div class="company1">
            <div class="label-box1">
              <span class="main">No. Of Doc</span><span class="required">*</span><br>
              <span class="main_text">No. Of Doc in agreement </span>
            </div>
            <input id="no-of-text" type="text" formControlName="documentsPerMonth" required name="doc"
                   placeholder="Number of documents" class="right_box1">
          </div>
          <div style="text-align:left;max-width: 20%;margin-left:28% !important; margin: 0.5% 0;" *ngIf="companyForm.controls.documentsPerMonth.errors && submitted">
            <span class="text_danger" >Number of Docs is required </span>
          </div>
          <br>


          <div class="company1">
            <div class="label-box1">
              <span class="main">No. Of Users</span><span class="required">*</span><br>
              <span class="main_text">No.Of Users allowed</span>
            </div>
            <input id="no-of-text" type="text" formControlName="noOfUsers" required name="uname"
                   placeholder="Number of users" class="right_box1">
          </div>
          <div style="text-align:left;max-width: 20%;margin-left:28% !important; margin: 0.5% 0;" *ngIf="companyForm.controls.noOfUsers.errors && submitted">
            <span class="text_danger" >noOfUsers is required </span>
          </div>
          <br>
          <div class="company1">
            <div class="label-box1">
              <span class="main">No. Of IP/MAC </span><br>
              <span class="main_text">No. Of IP/MAC</span></div>
            <input id="no-of-text" type="text" formControlName="macAddresses"  name="ip" placeholder="Numbers of  IP/MAC"
                   class="right_box1">

          </div>

          <br>
          <div class="company1">
            <div class="label-box1">
              <span class="main">Active Period</span><br>
              <span class="main_text">No. of days for active status duration</span>
            </div>
            <input id="activePeriod" type="text" formControlName="activePeriod" required name="activePeriod"
                    class="right_box1" value = "0">
          </div>
          <br>
          

          <div class="company"  >
            <div class="label-box1">
              <span class="main">Features Activated</span><span class="required">*</span><br>
              <span class="main_text">Enable Features </span>
            </div>
                      <div class="ng-prime-multiselect">

          <p-multiSelect  [options]="features" name="name" formControlName="features"
          optionLabel="name"  defaultLabel="Select Features"  display="chip">
      </p-multiSelect>
      <div style="text-align:left;max-width: 70% ;" *ngIf="companyForm.controls.features.errors && submitted">
        <span class="text_danger" >Must Select one Feature </span>
      </div>
      </div>
     
          </div>
          
          <div style="text-align:left;max-width: 20%;margin-left:28% !important;" *ngIf="featuresNotSelected">
            <span class="text_danger">Please Select the Features</span>
          </div>

          <div *ngIf="JsonArrayContains(companyForm.get('features').value,'pdfParsing')||JsonArrayContains(companyForm.get('features').value,'consolidation')" class="company">
            <div class="label-box1">
              <span class="main">Output Configuration</span><span class="required">*</span><br>
              <span class="main_text">Xls to be populated</span>
            </div>


            <div *ngIf="JsonArrayContains(companyForm.get('features').value,'pdfParsing')" class="select-output-dropdown"
               style="margin-left: 0!important;">
              <div class="drop1">
                <p-multiSelect   [options]="pdfParsingFormats" 
                  optionLabel="name"  
                  formControlName="pdfParsingReportConfig"
                  defaultLabel="Select a Format" 
                  name="name" 
                  [maxSelectedLabels]="3" >
            </p-multiSelect>

              </div>
            </div>
            <div *ngIf="JsonArrayContains(companyForm.get('features').value,'consolidation')" class="select-output-dropdown">
              <div class="drop1">
                <p-multiSelect [options]="consolidationFormats" 
                  optionLabel="name" 
                  formControlName="consolidationReportConfig"
                  defaultLabel="Select a Format"   
                  name="name"  
                  [maxSelectedLabels]="3"                   
                  >
            </p-multiSelect>

              </div>
            </div>



          </div>

          <div class="start-end-date-both">

            <div style="text-align:left;max-width: 40%;display: inline-block; margin-left:8% !important;" *ngIf="JsonArrayContains(companyForm.get('features').value,'pdfParsing') &&companyForm.controls.pdfParsingReportConfig.errors && submitted">
              <span class="text_danger" >pdfParsingReportConfig is required </span>
            </div>
            <div style="text-align:left;max-width: 40%;display: inline-block; margin-left:36% !important;" *ngIf="JsonArrayContains(companyForm.get('features').value,'consolidation')&&companyForm.controls.consolidationReportConfig.errors && submitted">
              <span class="text_danger" >consolidationReportConfig is required </span>
            </div>

          </div>
          <br>
          <div class="company" id="company">
            <div class="label-box1">
              <span class="main">Documents Activated</span><span class="required">*</span><br>
              <span class="main_text">Enable Documents </span>
            </div>

          <div class="ng-prime-multiselect">
                     <p-multiSelect  [options]="documentCategoriesArr" name="name" formControlName="documentCategories"
          optionLabel="name"  defaultLabel="Select Category"  display="chip"
          [maxSelectedLabels]="3" >
          
      </p-multiSelect>
      <div style="text-align:left;max-width: 70%;" *ngIf="companyForm.controls.documentCategories.errors && submitted">
        <span class="text_danger" >Must Select one documentCategory </span>
      </div>

    </div>
    
 
              </div>
          <div style="text-align:left;max-width: 20%;margin-left:28% !important;" *ngIf="documentsNotSelected">
            <span class="text_danger">Please Select the Documents</span>

          </div>


          <div *ngIf="JsonArrayContains(companyForm.get('features').value,'pdfParsing')" class="company"  >
            <div class="label-box1">
              <span class="main">Document Sub Categories</span><br>
              <span class="main_text">Enable Sub Categories </span>
            </div>
                      <div class="ng-prime-multiselect">

          <p-multiSelect  [options]="documentSubCategoriesArr" name="name" formControlName="documentSubCategories"
          optionLabel="name"  defaultLabel="Select Document Sub Categories"  display="chip">
      </p-multiSelect>
      <div style="text-align:left;max-width: 70% ;" *ngIf="companyForm.controls.documentSubCategories.errors && submitted">
        <span class="text_danger" >Must Select one Sub Categories </span>
      </div>
      </div>
     
        </div>

          <div class="company">
            <div class="label-box1">
              <span class="main">Plans & Pricing</span><span class="required">*</span><br>
            </div>

            <div class="select-output-dropdown" style="margin-left: 0!important;">
              <div class="drop1">

                <!-- <mat-select >
                  <mat-option *ngFor="let pricing of pricingPlans"
                              [value]="pricing">{{pricing}}</mat-option>
                </mat-select> -->
                <p-dropdown [options]="pricingPlans" formControlName="billingPlan"  placeholder="Pricing Plan"></p-dropdown>


              </div>
            </div>

            <input type="text" style="margin-left: 7%!important;" required name="pricing" formControlName="pricing" placeholder="price"
                   class="right_box">




          </div>

          <div class="start-end-date-both">

            <div style="text-align:left;max-width: 40%;display: inline-block; margin-left:8% !important;" *ngIf="companyForm.controls.billingPlan.errors && submitted">
              <span class="text_danger" >Pricing Plan is required </span>
            </div>
            <div style="text-align:left;max-width: 40%;display: inline-block; margin-left:36% !important;" *ngIf="companyForm.controls.pricing.errors && submitted">
              <span class="text_danger" >Pricing is required </span>
            </div>

          </div>


          <div class="line-3"></div>

          <div class="btn_tab">
            <button type="button" class="cancel-copy" (click)="clearForm()">
              Cancel
            </button>
            <button class="submit-copy" (click)="onSubmit() " type="submit">Submit</button>
          </div>


        </div>
      </div>
    </div>


  </form>
  <div class="lower_container">
    <table cellpadding="2">
      <tr>
        <th>CompanyName</th>
        <th>StartDate,<br>EndDate</th>
        <th>Plan<br>Pricing</th>
        <th>Retention<br>Period</th>
        <th>Active<br>Period</th>
        <th>No.of<br>doc</th>
        <th>no.of<br>user</th>
        <th>no.of<br>IPS</th>
        <th>Features<br>Enable</th>
        <th>Documents<br>Enabled</th>
        <th>Output<br>config</th>
        <th>APIKey</th>
        <th>Action</th>
      </tr>
      <tr *ngFor="let company of companyList">
        <td class="td-ellipsis" title="{{company.company?.name}}">{{company.company?.name}}</td>
        <td><span class="start">{{company.startDate}}</span><br><span class="end">{{company.endDate}}</span></td>
        <td>{{company.company.billingPlan}}<br><span class="pricing">{{company.company.pricing}}</span></td>
        <td>{{company.company.documentRetentionPeriod}}</td>
        <td>{{company.company.activePeriod}}</td>
        <td>{{company.documentsPerMonth}}</td>
        <td>{{company.noOfUsers}}</td>
        <td>{{company.macAddresses?.length}}</td>
        <td class="td-ellipsis" title="{{company.features}}">{{company.features}}</td>
        <td class="td-ellipsis" title="{{company.documentCategories}}">{{company.documentCategories}}</td>
        <td class="td-ellipsis" title="{{company.reports}}">{{company.reports}}</td>
        <td><i class="material-icons apiKey" (click)="op.toggle($event)">vpn_key</i>
        
          <p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '30%'}">
            <span>{{company.apiKey}}</span>
        </p-overlayPanel>
        </td>

  

        <td>
          <div class="Action">
            <i class="material-icons md-flag" (click)="companyData(company)"
               onclick="document.getElementById('deactivate-company-tab').style.display='block'">flag</i>
            <i class="material-icons md-edit" (click)="editCompany(company)">edit</i>
          </div>
        </td>
      </tr>
    </table>
  </div>
   <app-pagination [totalCount]="totalCount" (click)="reload()"></app-pagination> 

</div>

<!-- Start-End date Filter Html start-->

<div id="start-end-date-filter-tab" class="start-end-date-modal">
  <div class="filter-tooltiptext">
    <div class="filter-uploading-details">
      <div class="filter-inner-tab">
        <span class="filter-upload-timings">Upload Timings</span><br>
        <span class="filter-select-start-date-an-text">Select start date and end date </span>
      </div>
      <input class="filter-start-date" type="text" id="start-date" name="start-date" onfocus="(this.type='date')"
             placeholder="Start Date">

      <input class="filter-start-date" type="text" id="end-date" name="start-date" onfocus="(this.type='date' )"
             placeholder="End Date">
    </div>
    <div class="filter-line"></div>

    <div class="filter-btn-tab ">
      <button class="clear-filter " type="reset " value="Reset"
              onclick="document.getElementById( 'start-end-date-filter-tab').style.display='none' ">Clear Filter
      </button>

      <button class="filter-cancel "
              onclick="document.getElementById( 'start-end-date-filter-tab').style.display='none' ">Cancel
      </button>

      <button class="filter-apply "
              onclick="document.getElementById( 'start-end-date-filter-tab').style.display='none' ">Apply
      </button>
    </div>
  </div>
</div>

<!-- Deactivate company pop-up html starts -->

<div id="deactivate-company-tab" class="deactivate-company-modal">
  <div class="deactivate-outer-tab">
    <div class="deactivate-inner-tab">
      <div class="deactivate-tittle-box">
        <label class="deactivate-warning-tittle">WARNING</label>
      </div>
      <div class="deactivate-warning-message">
        <!-- <span>Are you sure you want to deactivate "{{companyToToggleName}}"?</span> -->
      </div>
      <div class="deactivate-btn-tab">
        <button class="deactivate-cancle"
                onclick="document.getElementById('deactivate-company-tab').style.display='none'">CANCEL
        </button>
        <button class="deactivate-yes" (click)="reload()"
                onclick="document.getElementById('deactivate-company-tab').style.display='none'">YES
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Deactivate company pop-up html Finish Here -->
