import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';

@Injectable()
export class SharedService {

  mimeTye = {
    'pdf':'application/pdf',
    'xls':'application/ms-excel',
    'xlsx':'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'pptx':'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'ppt': 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  }

  PageNumber :number =1;
  public lastPage :boolean = false;

  public gapsheetPath:string='/upload' ;
  // Observable string sources
  private missionAnnouncedSource = new Subject<boolean>();

  // Observable boolean streams
  loginSuccessful$ = this.missionAnnouncedSource.asObservable();

  // Service message commands
  loginResult(success: boolean) {
    this.missionAnnouncedSource.next(success);
  }
  setPageNumber(value : number) {
    this.PageNumber = value;
  }


  getMimeType = (key: string ) => (this.mimeTye[key])

  getPageNumber() {
    return this.PageNumber;
  }
  setGapSheetOriginCall(path){
    this.gapsheetPath = path;
  }
  getGapSheetOriginCall(){
    return this.gapsheetPath;
  }
}
