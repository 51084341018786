<div class="both">
    <div class="main_text">
        <div class="logo">
            <img alt="mfit-logo" class="Path" src="assets/img/MFIT_final.png">
        </div>
        <div class="logo_text_container">
            <span>MFIT TECHNOLOGIES
            </span>
        </div>
    </div>
</div>
    <div class="main">
        <div class="head_text">
            <span>Forgot<br> Password?</span>
        </div>
        <form #heroForm="ngForm" (ngSubmit)="onSubmit(heroForm)">

            <div class="Forgot">
                <div class="Forgot_text" [class.has.error]="email.invalid && email.touched"><span>Email</span></div>

                <input type="Email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" class="forgotForm_Forgot"
                    name="email" #email="ngModel" ngModel id="email" (click)="refreshMessage()"
                    placeholder=" We will send a password reset link on the registered email.">
                <div *ngIf="email.errors && (email.dirty||email.touched) && heroForm.submitted">
                    <span class="text_danger" [hidden]="!email.errors.required">Email is required </span>
                    <span class="text_danger" [hidden]="!email.errors.pattern">Input should be email format </span>
                </div>

                <span class="text_danger">{{showMessage}}</span>

            </div>

            <!-- <button class="submit" type="submit" name="submit" (click)="onSubmit(heroForm)"
                [disabled]="heroForm.invalid">SUBMIT</button> -->

                <button class="submit" type="submit" >SUBMIT</button>

        </form>


    </div>
