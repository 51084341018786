import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {environment} from "../../../environments/environment";
import {BaseService} from "../../base.service";
import {SharedService} from "../../shared.service";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
submitted=false;
  constructor(private router:Router, private baseService: BaseService, private  sharedService: SharedService) { }
  phoneNumbervalid : boolean =true
  public user:any={};
  profileUser:any={}

  ngOnInit(): void {
    this.user = localStorage.user? JSON.parse(localStorage.user):{};
  }
  gotoProfile() {
    this.router.navigate(['/my-profile']);
  }
  gotoChangePassword() {
    this.router.navigate(['/changePassword']);
  }
  onSubmit(){
    if(this.user.phoneNumber.toString().match('^[0-9]\\d')==null){
      this.phoneNumbervalid = false;
    }
    else {
      this.phoneNumbervalid = true;

      this.profileUser.name = this.user.name;
      this.profileUser.phoneNumber = this.user.phoneNumber;
      this.profileUser.address = this.user.address;
      this.baseService.update(environment.restPath+'user/updateUser/'+this.user.id, this.profileUser).subscribe(
        (response) =>
        {
          console.log(response)
          localStorage.setItem("user", JSON.stringify(this.user))
          this.sharedService.loginResult(true);


        })
    }

    }



}
