import {Component, OnInit, ViewChild} from '@angular/core';
import { BaseService } from 'src/app/base.service';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/shared.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { HttpResponse } from '@angular/common/http';
@Component({
  selector: 'app-upload-ocr',
  templateUrl: './upload-ocr.component.html',
  styleUrls: ['./upload-ocr.component.css']
})
export class UploadOcrComponent implements OnInit {
  page: Number =0;

  constructor( private fb : FormBuilder ,private baseService:BaseService, private sharedService:SharedService,private toastr: ToastrService,) { }

  uploadButtonText = "ADD FILES";

  errorMsg :string = "";

  file : FormControl;

  x :FormArray;

  uploads:any;
  password : FormControl;
  filesToUplaod:any = [];
  userActivityList: any = [];
  uploadOuterFileForm : FormGroup;
  pageNum=1;
  total=0;

  uploadFileForm = FormGroup;

  uploadItems:FormArray;

  totalCount : number;

  documenttoDelete;
  documenttoDeleteName : string = "";

  data : string = "";


  isLastPage:boolean = false;
  spinner:boolean = false;



  showModal : boolean = false;
  @ViewChild('fileInput') fileInput: any;
  ngOnInit(): void {
    this.uploadOuterFileForm = this.fb.group({
      uploadItems : this.fb.array ([ ])
    });

    this.getUploads(this.sharedService.getPageNumber());
    this.sharedService.lastPage = true;
  }

  reload(){
    this.getUploads(this.sharedService.getPageNumber());
  }
  delete( ) {
    this.baseService.delete(environment.restPath+'file/ocr', this.documenttoDelete.id).subscribe(
      (data) => {
        this.reload();
      })
      this.toastr.success('Document Deleted  Successfully','',{
        positionClass:"toast-top-left",  timeOut: 4000
      });
    console.log(event);
  }


  // downloadOutput(userActivity){
  //   window.location.href = environment.restPath+'file/download/reports-ocr/'+ userActivity.outputDocumentLink;
  // }

  // downloadpdf(userActivity){
  //   window.location.href = environment.restPath+'file/download/source-ocr/'+ userActivity.sourceDocumentLink;
  // }

  downloadOutput(upload){
    if(upload.outputDocumentLink && upload.outputDocumentLink.trim().length>0)
    {
      const url = environment.restPath+'file/download/reports-ocr/'+ upload.outputDocumentLink;
      this.download(url, "xls")
    }
 }

 downloadPdf(upload){
    const url = environment.restPath+'file/download/source-ocr/'+ upload.sourceDocumentLink;
    this.download(url, 'pdf')

  }

  download(url,mimeTye:string): any {
    this.baseService.download(url).subscribe( 
      (res: HttpResponse<Blob>) => this.downloadSuccessful(res, mimeTye)),
      (err) =>{
        console.log(err)
      }
    }

  downloadSuccessful(response: HttpResponse<Blob>, mimeTye:string): void {
    let fileName = 'DummyName.'+ mimeTye ;
    const contentDisposition = response.headers.get('Content-Disposition');
    if (contentDisposition) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
      const blob = new Blob([response.body], { type: this.sharedService.getMimeType(fileName.split(".")[1]) });
      const URL = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = URL;
      anchor.click();
    }
  }


  fileToDelete(file){
    this.documenttoDelete = file;
    this.documenttoDeleteName = file.documentName;
  }



  getUploads(page)
  {
    this.baseService.getData(environment.restPath+'file/list/ocr/page/'+page+ '?thisUserOnly=true').subscribe(
      (data) => {
        this.uploads = data.result.list;
        this.totalCount = data.result.totalCount;       });
  }

  onFileDropped($event) {
    this.onSelectFile($event);
    this.showModal = true;
  }
  onSelectFile(event) {
    this.showModal = true;
    var filesAmount = event;
    for (let i = 0; i < filesAmount.length; i++) {
      let fItem:any  = ['file' , filesAmount[i]];



      (  <FormArray> this.uploadOuterFileForm.get('uploadItems')).push(this.addFileFormGroup(filesAmount[i]))


      this.filesToUplaod.push(fItem);

    }
    this.uploadButtonText = "ADD MORE FILES";


  }


  addFileFormGroup (fileItem :any): FormGroup{
    return this.fb.group({
      file : new FormControl(fileItem,Validators.required),
      password : new FormControl(null)
    })


  }

  public greaterThan(nameLength: number, num: number) {
    return nameLength > num;
  }
  public removeDocFromPopUp(i : number){

    (  <FormArray> this.uploadOuterFileForm.get('uploadItems')).removeAt(i);
    this.fileInput.nativeElement.value = '';
    if((  <FormArray> this.uploadOuterFileForm.get('uploadItems')).length==0){
      this.uploadButtonText = "ADD FILES";
    }

  }
  uploadFiles( event ){
    let formData = new FormData();

    this.uploadItems = this.uploadOuterFileForm.get('uploadItems') as FormArray;

    for (let group of this.uploadItems.controls ) {
      this.spinner = true;

      var g = <FormGroup>group;
      var file = g.controls.file.value;
      formData.append('file', file, file.name);
      var pass =  g.controls.password.value
      formData.append('password',pass!= null ? pass: ' ');

    }

    this.baseService.ocrFileUpload(formData)
      .subscribe(
        (data)=>{
          this.spinner = false;
          if(data.result==="InvalidPassword"){
            this.errorMsg = "Invalid Password";
          }else if (data.result==="License Error")
          {
            this.errorMsg = "License Error.Please contact MFIT!!!";
          }
          else{

            for (let i=0; i<this.uploadItems.controls.length ;i++) {
              this.removeDocFromPopUp(i);
            }

            this.reload();
            this.showModal = false;
            this.toastr.success('Document Extracted Successfully','',{
              positionClass:"toast-top-left",  timeOut: 4000
            });
          }

        },error => {


          this.spinner = false;
          console.error();


        });


  }

  closeModal(){
    this.showModal = false;
  }


}
