<div class="main_left_container">
  <div class="middle_container">
    <div class="recent_title">
      <span class="txt">User Management Console</span>
    </div>
    <div class="box_search">


      <app-search  #userSearchComponent class="search" (onSelect)="onUserSelected($event)" collectionType="Users" searchField="name" 
      controlId="user-name-id"></app-search>


      <button [disabled]="!searchEnabled" [ngClass]="searchEnabled?'clear-search-enabled':'clear-search-disabled'" 
      type="button" (click)="clearSearch()" >
          <i class="material-icons" id="filter-icon">close</i>
        <span class="txt_filter">Clear</span> </button>

      <button class="add-new-btn" (click)="addNewUserPopUp()" >
        <div class="add-button">
          <i class="material-icons" id="add-new-icon">add</i>
          <span class="addnew">Add New</span>
        </div>
      </button>

      <button *ngIf='adminUser.roles != "super_admin" && downloadExcelActive' class="add-new-btn" (click)="downloadUsersList()" >
        <div class="add-button">
          <i class="material-icons" id="download-icon">download</i>
          <span  class="downloadExcel">Export Users</span>
        </div>
      </button>
      <button *ngIf='adminUser.roles != "super_admin" && !downloadExcelActive' class="disable-add-new-btn" >
        <div class="add-button">
          <!-- <i class="material-icons" id="download-icon">download</i> -->
          <span  class="downloadExcel">Exporting...</span>
        </div>
      </button>   

      <!-- Register Pop-up Start -->

      <form [formGroup]="userForm" >
        <div id="add-new-form" class="add-new-modal">
          <div class="add-new-outer-tab arrow-top">
            <div class="label-tab">
              <div class="label-box">
                <div *ngIf="showCompanySelection" class="inner-label-box">
                  <span class="big-font">Company Name</span><span class="required">*</span><br>
                  <span class="small-font" style="width: 150px;">Enter client’s company name </span>
                </div>
                <div *ngIf="showCompanySelection" class="text-box-tab">

                  <app-search  class="input-text-box-search" (onSelect)="onCompanySelected($event)" collectionType="ORGANIZATION" searchField="name" placeholder="{{companyPlaceHolder}}" controlId="company"></app-search>
                </div>
              </div>

              <div class="label-box">
                <div class="inner-label-box">
                  <span class="big-font">Name</span><span class="required">*</span><br>
                  <span class="small-font">Name of the employee </span>
                </div>
                <div class="text-box-tab">
                  <input type="textbox" required class="input-text-box" name="name" maxlength="50" value="hello" placeholder="Name" #name formControlName="name">
                  <span class="text_danger" *ngIf="submitted && userForm.controls.name.invalid">Please enter user name </span>

                </div>
              </div>

              <div class="label-box">
                <div class="inner-label-box">
                  <span class="big-font">Role</span><span class="required">*</span><br>
                  <span class="small-font" style="margin-left: -1%; margin-top: 0%;">Role of the employee</span>
                </div>
                <div class="text-box-tab">
                  <select required class="input-text-box" style="width: 97%; height:40px;margin-top:0%" name="role" id="role" placeholder="Select Role" #roles formControlName="roles">
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                  </select>
                  <span class="text_danger" *ngIf="submitted && userForm.controls.roles.errors">Please select a role </span>

                </div>
              </div>

              <div class="label-box">
                <div class="inner-label-box">
                  <span class="big-font">Supervisior</span><br>
                  <span class="small-font">Supervisior of the employee </span>
                </div>
                <div class="text-box-tab">
                  <app-search class="input-text-box-search"   (onSelect)="onSupervisorSelected($event)" collectionType="Users" searchField="name" placeholder="{{supervisorPlaceHolder}}" controlId="supervisor" filters="{{superVisorQueryFilter}}"></app-search>
                </div>
              </div>

              <div class="label-box">
                <div class="inner-label-box">
                  <span class="big-font">Email Id</span><span class="required">*</span><br>
                  <span class="small-font">Email Id of the employee </span>
                </div>
                <div class="text-box-tab">
                  <input type="textbox" required  class="input-text-box1" name="email" id="" placeholder="Email ID" #email formControlName="email">
                  <span class="text_danger" *ngIf="submitted && userForm.controls.email.invalid">Please enter a valid EmailID </span>

                </div>
              </div>

              <div class="label-box">
                <div class="inner-label-box">
                  <span class="big-font">Phone Number</span><span class="required">*</span><br>
                  <span class="small-font">Phone No. of the employee </span>
                </div>
                <div class="text-box-tab">
                  <input type="textbox" required  class="input-text-box1" name="phone" id="phone" placeholder="Phone Number " #phoneNumber formControlName="phoneNumber">
                  <span class="text_danger" *ngIf="submitted && userForm.controls.phoneNumber.invalid">Please enter your valid phone number </span>

                </div>
              </div>

              <div class="label-box">
                <div class="inner-label-box">
                  <span class="big-font">Set Password</span><span class="required">*</span><br>
                  <span class="small-font">Set Password Here</span>
                </div>
                <diV  class="text-box-tab">
                  <input  type="password" class="input-text-box1" name="password" #password formControlName="password" id="password" placeholder="Enter Password">
                  <span class="text_danger" *ngIf="submitted && userForm.controls.password.invalid">Must contain at least one number and one uppercase and lowercase
                                            letter,and minimum 8 or more characters and  one special character </span>
                </diV>
              </div>
            </div>
            <div class="plane-line"></div>

            <div class="company-btn-tab">
              <button class="cancel-copy" type="button"  (click)="resetForm()">Cancel</button>
              <button class="submit-copy" (click)="onSubmit(userForm)"   >Submit</button>
            </div>

          </div>
          <app-spinner class="spinner" [showSpinner]="spinner"></app-spinner>

        </div>
      </form>

      <!-- Register Pop-up Ends -->

    </div>
  </div>
  <div class="lower_container">
    <table cellpadding="2">
      <tr>
        <th>User Name</th>
        <th>Company Name</th>
        <th>Email</th>
        <th>Role</th>
        <th>Supervisor</th>
        <th>Create Date</th>
        <th>Last Login Date</th>
        <th>Re-Activation Date</th>
        <th>De-Activation Date</th>
        <th>Status</th>

        <th>Action</th>
      </tr>
      <tr *ngFor="let user of users">
        <td title="{{user.name}}"class="doc-name-col">{{user.name}}</td>
        <td  title="{{user.companyName}}"class="doc-name-col">{{user.companyName}}</td>
        <td >{{user.email}}</td>
        <td>{{user.roles}}</td>
        <td>{{user.supervisor?.displayName}}</td>
        <td>{{user.createDate|date }}</td>
        <td>{{user.lastLoginDate|date :'dd-MMM-yyyy HH:mm' }}</td>
        <td>{{user.reActivationDate|date : 'dd-MMM-yyyy HH:mm'}}</td>
        <td>{{user.deActivationDate|date :'dd-MMM-yyyy HH:mm' }}</td>
        <!-- <td>{{user.active }}</td> -->
        <!-- <td>
          <span *ngIf="user.active">Active</span>
          <span *ngIf="!user.active">
            {{ user.deActivatedBy === 'System' ? 'Dormant' : 'Inactive' }}
          </span>
        </td>
         -->
        
         <td>
          <span *ngIf="user.active">Active</span>
          
          <span *ngIf="!user.active">
            <!-- Display 'Dormant' with an option to change the status -->
            <span *ngIf="user.deActivatedBy === 'System'; else inactiveTemplate">
              <a (click)="onStatusDormantClick(user)" style="text-decoration: underline; cursor: pointer;">
                Dormant
              </a>
            </span>
            
            <!-- Display 'Inactive' if the status is not 'Dormant' -->
            <ng-template #inactiveTemplate>
              {{ 'Inactive' }}
            </ng-template>
          </span>
        </td>
        

        <td>
          <div class="Action">
            <i class="material-icons md-edit" (click)="userEdit(user)" onclick="document.getElementById('add-new-form').style.display='block'">edit</i>
            <i class="material-icons md-flag" [style.color]="user.active?'#0090A6':'#D0021B'" (click)="userStatusToggle(user)" onclick="document.getElementById('deactivate-user-tab').style.display='block'">flag</i>
          </div>
        </td>
      </tr>
    </table>
  </div>

  <app-pagination [totalCount]="totalCount" (click)="reload()"></app-pagination>
</div>

<!-- start end date Html start -->

<div id="start-end-date-filter" class="start-end-date-modal">
  <div class="filter-tooltiptext">
    <div class="filter-uploading-details">
      <div class="filter-inner-tab">
        <span class="filter-upload-timing-text">Upload Timings</span><br>
        <span class="filter-select-start-date-an-text">Select start date and end date </span>
      </div>
      <input class="filter-start-date" type="text" id="start-date" name="start-date" onfocus="(this.type='date')" placeholder="Start Date">

      <input class="filter-start-date" type="text" id="end-date" name="start-date" onfocus="(this.type='date' )" placeholder="End Date">
    </div>
    <div class="filter-line"></div>

    <div class="filter-btn-tab ">
      <button class="clear-filter " type="reset " value="Reset" onclick="document.getElementById( 'start-end-date-filter').style.display='none' ">Clear Filter</button>

      <button class="filter-cancel " onclick="document.getElementById( 'start-end-date-filter').style.display='none' ">Cancel</button>

      <button class="filter-apply " onclick="document.getElementById( 'start-end-date-filter').style.display='none' ">Apply</button>
    </div>
  </div>
</div>

<!-- start end date Html end -->


<!-- Deactivate user pop-up html starts -->

<div id="deactivate-user-tab" class="deactivate-user-modal">
  <div class="deactivate-outer-tab">
    <div class="deactivate-inner-tab">
      <div class="deactivate-warning">
        <label class="deactivate-warning-tittle">WARNING</label>
      </div>
      <div class="deactivate-warning-message">
        <span>Are you sure you want to {{userStatusString}} "{{userToToggleName}}" ?</span>
      </div>
      <div class="deactivate-btn-tab">

        <button class="deactivate-cancel" onclick="document.getElementById('deactivate-user-tab').style.display='none'">CANCEL</button>
        <button class="deactivate-yes" (click)="deactivateUser()"onclick="document.getElementById('deactivate-user-tab').style.display='none'">YES</button>
      </div>
    </div>
  </div>
</div>


<!-- Confirmation dialog pop up -->

<div id="confirmation-dialog" class="confirmation-modal">
  <div class="confirmation-outer-tab">
    <div class="confirmation-inner-tab">
      <div class="confirmation-alert">
        <label class="confirmation-tittle">Confirm Status Change</label>
      </div>
      <div class="confirmation-alert-message">
        <span>Do you want to change the user status to Inactive?</span>
      </div>
      <div class="confirmation-btn-tab">

        <button class="confirmation-cancel" (click)="onCancel()">CANCEL</button>
        <button class="confirmation-yes" (click)="onConfirm()">YES</button>
      </div>
    </div>
  </div>
</div>

